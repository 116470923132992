module.exports = [{
      plugin: require('../node_modules/@chakra-ui/gatsby-plugin/gatsby-browser.js'),
      options: {"plugins":[],"isResettingCSS":true,"isUsingColorMode":true,"portalZIndex":40},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"49321eac52a5f848242096ae25b19dd4"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-RQVXCRP599","head":false,"anonymize":true,"respectDNT":true,"pageTransitionDelay":0,"defer":false,"sampleRate":5,"siteSpeedSampleRate":10,"exclude":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-RQVXCRP599"]},
    }]
